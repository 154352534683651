.events__list-item {
    margin: 1rem 0;
    padding: 1rem;
    /* border: 1px solid #5101d1; */
    /* display: flex;
    justify-content: space-between; */
    align-items: center;
    list-style-type: none;
}

.events__list-item h1 {
    margin: 0;
    font-size: 1.5rem;
    color: #216378;
}

.events__list-item h2 {
    margin: 0;
    font-size: 1rem;
    color: #7c7c7c
}

.events__list-item p {
    margin:0 ;
}


.btn-calltoaction {
    padding: 10px 20px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 12px;
    background: #14613d;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}

.event-item {

    display:flex;
    justify-content: space-between;

}

.event-item-button {
    align-self: end;
    font-size: 25px;
}

