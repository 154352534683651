

ul {
    list-style: none;
  }
  
  .about-image {
    display: block;
  }
  
  .container-about {
    display: flex;
    align-items: center;
    /* height: 100vh; */
    padding: 0 5vw;
  }
  
  .accordion {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin: 0;
    padding: 0;
  }
  
  .accordion__item {
    flex-shrink: 1;
    transition: flex-shrink 0.6s cubic-bezier(0.18, 0.44, 0.46, 1),
      filter 0.6s cubic-bezier(0.18, 0.44, 0.46, 1);
    filter: grayscale(90%);
    min-width: 10px;
  }
  
  .accordion__item_focused {
    flex-shrink: 0;
    filter: none;
  }
  
  .accordion__item > img {
    object-fit: cover;
    width: 100%;
    max-width: 250px;
    transition: box-shadow 1.2s cubic-bezier(0.18, 0.44, 0.46, 1);
    pointer-events: none;
  }
  
  .accordion__item_focused > img {
    --glow-color: #000;
    box-shadow: 0px 0px 30px 3px var(--glow-color);
  }
  